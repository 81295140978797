import React from "react"
import scrollIcon from "../images/scroll-icon.svg"
import { useTranslation, Trans } from "react-i18next"

const ScrollBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="inline-block bg-dark block-utils absolute">
      <img
        src={scrollIcon}
        alt=""
        width="17"
        height="29"
        className="fill-white"
      />
      <p className="text-xs uppercase text-white">{t('general.checkOffer')}</p>
    </div>
  )
}
export default ScrollBlock;