/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from './footer'
import Header from "./header"
import { I18nextProvider } from "react-i18next"
import i18next from "../i18next"

const Layout = ({ children, props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div id="main" className="main mx-auto">
      <div>
        <main className="max-w-screen-xxl">
          <Header
            lang={props.pageContext.langKey}
            path={props.pageContext.slug}
            siteTitle={data.site.siteMetadata.title}
          />
          {children}
        </main>
        <Footer
          lang={props.pageContext.langKey}
          path={props.pageContext.slug}
        ></Footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
