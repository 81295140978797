export default {
  pl: {
    about: "o-nas",
    fleet: "flota",
    career: "kariera",
    contact: "kontakt",
    title: "J.Pietrzak - Kompleksowe usługi transportowe na terenie Europy",
    desc: `Działamy na rynku od 1996 r. Specjalizujemy się w świadczeniu kompleksowych usług transportowych w zakresie obsługi międzynarodowego obrotu towarów w Europie. Nasza oferta transportowa skierowana jest do małych, średnich jak i dużych przedsiębiorstw oczekujących sprawnej i niezawodnej obsługi. `,
  },
  en: {
    about: "about",
    fleet: "fleet",
    career: "career",
    contact: "contact",
    title: "J.Pietrzak - Transport company J.Pietrzak",
    desc:
      "We have been operating on the market since 1996. We specialize in providing comprehensive transport services #in the scope of handling international circulation of goods in Europe. Our transport offer is addressed to small, medium-sized and large businesses that require efficient and reliable service.",
  },
  de: {
    about: "uber-uns",
    fleet: "flotte",
    career: "karriere",
    contact: "kontakt",
    title: "J.Pietrzak - Transportunternehmen J.Pietrzak",
    desc:
      "Wir sind seit 1996 tätig und spezialisieren uns auf umfassende Transportdienstleistungen #im Bereich des internationalen Güterverkehrs in Europa. Unser Transportangebot richtet sich an kleine, mittelständische und große Unternehmen, die schnelle und verlässliche Dienstleistungen erwarten.",
  },
}
