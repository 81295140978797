import { Link } from "gatsby"
import React from "react"
import mgLogo from '../images/icon_07.svg';
import scrollIcon from '../images/scroll-icon.svg';
import { useTranslation } from "react-i18next"


const Footer = ({ siteTitle }) => {
  const { t } = useTranslation();
  return (
    <footer className="footer px-6 uppercase relative">
      <div className="max-w-screen-xl mx-auto">
        <Link
          to="#banner"
          className="footer-scroll z-10 inline-block absolute cursor-pointer"
        >
          <img src={scrollIcon} width="17" />
          <p className="uppercase text-xs">{t("general.up")}</p>
        </Link>
        <div className="block md:flex flex-row items-center justify-between">
          <nav className="footer-nav font-bold">
            <Link to={t("general.aboutHref")} className="footer-nav__pagelink px-8">
              {t("general.about")}
            </Link>
            <Link to={t("general.fleetHref")} className="footer-nav__pagelink px-8">
              {t("general.fleet")}
            </Link>
            <Link to={t("general.careerHref")} className="footer-nav__pagelink px-8">
              {t("general.career")}
            </Link>
            <Link to={t("general.contactHref")} className="footer-nav__pagelink px-8">
              {t("general.contact")}
            </Link>
            <a href={t("general.isoHref")} className="footer-nav__pagelink px-8">
              {t("general.iso-cert")}
            </a>
          </nav>
        </div>
        <div className="block md:flex flex-row items-center justify-between text-sm">
          <nav className="footer-info">
            <a href="" className="footer-info_link">
              COPYRIGHT &copy; 2020 Jpietrzak.pl
            </a>
          </nav>
          <p className="mt-2 md:mt-0">
            {t('general.madeby')}{" "}
            <img className="inline-block ml-2" src={mgLogo} width="18" />
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;